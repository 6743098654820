import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import PageTitle from "../components/PageTitle"
import PostList from "../components/PostList"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `React Js`,
        `Node Js`,
        `MongoDb`,
        `Blog`,
        `Web Developement`,
        `web dev`,
        `Tutorials`,
        `JavaScript`,
        `Data Structures and Algorithms`,
        `Angular Js`,
        `C`,
        `C++`,
        `Mobile App Development`,
      ]}
      description="CodeParadox Latest Code Tutorials on various technologies fields like Web Development, Mobile App Development,Machine Learning, AI, Data Structures and Algorithms. Latest Technologies like React Js, Vue Js, Angular Js,Node Js, JavaScript, React Native, Android Development, JavaScript, C, C++"
    />
    <div>
      <PageTitle pageTitle={"Learn code to build Something"} />
      <div className="post-background">
        <div className="post-container">
          {data.allMarkdownRemark.edges.map(post => (
            <PostList post={post} key={post.node.id} />
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date
            author
            categories
            tags
            date
            thumbnail {
              childImageSharp {
                fixed(width: 295, height: 200) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
          excerpt(pruneLength: 235)
          html
        }
      }
    }
  }
`

export default IndexPage
